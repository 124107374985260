import * as React from "react";
import { useNavigate } from "react-router-dom";

// external components
import Button from "@mui/material/Button";

import "./subgrid1item.css";

// Interfaces
import FormI from "../../interfaces/FormI";
interface Props {
  type: string;
  image: string;
  setFormData: React.Dispatch<React.SetStateAction<FormI>>;
  setContactForm: (value: boolean) => void;
}

const SubGridItem: React.FC<Props> = ({ type, image, setFormData, setContactForm }) => {
  let navigate = useNavigate();

  function handleClick() {
    navigate("/contactform");
    setContactForm(true);
    setFormData(prev => {
      return {...prev, appliance: type}
    });
  }

  return (
    <div className="sub-grid-1-item">
      <img src={image} alt={`Depicts a ${type} appliance`} />
      <Button
        onClick={handleClick}
        style={{ color: "#f9f9ff", backgroundColor: "#ff9100", fontFamily: "PT Sans",  fontSize: "15px" }}
        className="sub-grid-1-button"
        variant="contained"
        size="small"
      >
        {type} <br /> repairs
      </Button>
    </div>
  );
}

export default SubGridItem;