import React from "react";

import "./googleMapImport.css";

const GoogleMapImport: React.FC = () => {
  return (
    <iframe id="google-map" title="Appliance Authority Service Area" src="https://www.google.com/maps/d/u/0/embed?mid=1SFS7z8rrGCa54ts5W8QXgVdpAbNsAvg&ehbc=2E312F" height="480" allowFullScreen></iframe>
  );
};

export default GoogleMapImport;