import * as React from "react";
import { useNavigate } from "react-router-dom";

// internal components
import SubGrid1 from "../SubGrid/SubGrid1";
import GoogleMapImport from "../GoogleMapImport/GoogleMapImport";
import MapSelect from "../MapSelect/MapSelect";

import "./grid.css";

// Interfaces
import FormI from "../../interfaces/FormI";
import { Button } from "@mui/material";
interface Props {
  formData: FormI;
  setFormData: React.Dispatch<React.SetStateAction<FormI>>;
  setContactForm: (value: boolean) => void;
  setInfoPage: (value: boolean) => void;
}

const Grid: React.FC<Props> = ({ formData, setFormData, setContactForm, setInfoPage }) => {
  let navigate = useNavigate();

  React.useEffect(() => {
    window.scrollTo(0, 0);
    setContactForm(false);
    setFormData({
      fullName: "",
      phoneNumber: "",
      zipCode: "",
      problem: "",
      email: "",
      address: "",
      appliance: "",
      applianceDetails: "",
      warranty: false,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  function handleClick() {
    navigate("/contactform");
    setContactForm(true);
  }
 
  return (
      <div className="main-page">
        <div className="main-page-item image-div">
          <div>
            <h2>
              Quality Appliance Repair, Services and Parts in the Dallas-Fort Worth Area
            </h2>
            <Button
              onClick={handleClick}
              style={{ color: "#f9f9ff", backgroundColor: "#ff9100", fontFamily: "PT Sans", width: "20%", fontSize: "20px" }}
              variant="contained"
              size="small"
            >
              Schedule Service
            </Button>
          </div>
          <div>
          </div>
        </div>
        <div className="main-page-item map-div">
          <MapSelect formData={formData} setFormData={setFormData} setContactForm={setContactForm} />
          <GoogleMapImport />
        </div>
        <div className="main-page-item subgrid-div">
          <SubGrid1 setFormData={setFormData} setContactForm={setContactForm} />
        </div>
      </div>
  );
};

export default Grid;