import * as React from "react";

// external components
import Snackbar, { SnackbarOrigin } from "@mui/material/Snackbar";
import MuiAlert, { AlertProps } from "@mui/material/Alert";

const Alert = React.forwardRef<HTMLDivElement, AlertProps>(function Alert(
    props,
    ref,
) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

interface Props {
    openSnack: boolean,
    handleClose: (event?: React.SyntheticEvent | Event, reason?: string) => void;
    snackError: boolean,
    snackIncomplete: boolean,
}

const SnackbarNotification: React.FC<Props> = ({ openSnack, handleClose, snackError, snackIncomplete }) => {

    // eslint-disable-next-line
    const [state, _]= React.useState<SnackbarOrigin>({
        vertical: "top",
        horizontal: "center",
    })

    const { vertical, horizontal } = state;

    return (
        <div>
            <Snackbar anchorOrigin={{ vertical, horizontal }} open={openSnack} autoHideDuration={4000} onClose={handleClose}>
                <Alert onClose={handleClose} severity={snackIncomplete ? "warning" : snackError ? "error" : "success"}>{snackIncomplete ? "Please ensure you fill out required fields" : snackError ? "Form has not been submitted" : "Form has been submitted!"}</Alert>
            </Snackbar>
        </div>
    )
}

export default SnackbarNotification;