import * as React from "react";

import "./informationPage.css";

const InformationPage: React.FC = () => {
    React.useEffect(() => {
        window.scrollTo(0, 0);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <div className="InformationPage">
            <div className="InformationPage-Container">
                <div className="InformationPage-About">
                    {/* TODO: add information about business and customer service */}
                    <h3 className="InfoPageTitle">About:</h3>
                    <p>&ensp;Welcome to Appliance Authority, your go-to source for reliable and affordable appliance repair services. We have been serving the Dallas-Forth Worth area for over 8 years, and have built a reputation for providing top-notch service to our customers. Our team is trained to repair all major brands and models of appliances, including refrigerators, ovens, ranges, dishwashers, washing machines, and dryers. We pride ourselves on our prompt and efficient service, and will always do our best to get your appliances up and running as quickly as possible. We look forward to serving you and keeping your home running smoothly.</p>
                </div>
                <div className="InformationPage-PrivacyPolicy">
                    {/* TODO: include privacy policy about customer data */}
                    <h3 className="InfoPageTitle">Privacy Policy:</h3>
                    <p>&ensp;At Appliance Authority, we are committed to protecting the privacy of our customers. We do not store or sell any of your personal information. We only use your information to contact you and provide you with the services you have requested. We take precautions to protect your personal information from unauthorized access, use, or disclosure. If you have any questions or concerns about our privacy practices, please contact us at applianceauthority469@gmail.com. Thank you for choosing Appliance Authority for your service needs.</p>
                </div>
                <div className="InformationPage-Credits">
                    {/* TODO: give credit to Google Maps and Icons for use on website  */}
                    <h3 className="InfoPageTitle">Credits:</h3>
                    <ul>
                        <li><a href="https://www.google.com/maps/d/embed?mid=1SFS7z8rrGCa54ts5W8QXgVdpAbNsAvg&ehbc=2E312F" rel="noreferrer" target="_blank">Google Maps Import</a></li>
                        <li><a href="https://www.emailjs.com/legal/privacy-policy/" rel="noreferrer" target="_blank">EmailJS Privacy Policy (The Email Service We Use)</a></li>
                        <li><a href="https://www.flaticon.com/free-icons/refrigerator" title="refrigerator icons" rel="noreferrer" target="_blank">Refrigerator icons created by berkahicon - Flaticon</a></li>
                        <li><a href="https://www.flaticon.com/free-icons/stove" title="stove icons" rel="noreferrer" target="_blank">Stove icons created by Freepik - Flaticon</a></li>
                        <li><a href="https://www.flaticon.com/free-icons/washing-machine" title="washing machine icons" rel="noreferrer" target="_blank">Washing machine icons created by itim2101 - Flaticon</a></li>
                        <li><a href="https://www.flaticon.com/free-icons/dishwasher" title="dishwasher icons" rel="noreferrer" target="_blank">Dishwasher icons created by Eucalyp - Flaticon</a></li>
                    </ul>
                </div>
            </div>
        </div>
    )
}

export default InformationPage;