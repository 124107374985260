import * as React from "react";
import * as emailjs from "@emailjs/browser";

// external components
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";

// internal components
import ZipCodeSelect from "../ZipCodeSelect/ZipCodeSelect";
import SnackbarNotification from "../SnackbarNotification/SnackbarNotification";
import FormSubmitModal from "../FormSubmitModal/FormSubmitModal";

import "./contactForm.css";

// Interfaces
import FormI from "../../interfaces/FormI";
import EnvVars from "../../interfaces/EnvVars";
interface Props {
  formData: FormI;
  setFormData: React.Dispatch<React.SetStateAction<FormI>>;
  setContactForm: (value: boolean) => void;
}

const ContactForm: React.FC<Props> = ({ formData, setFormData, setContactForm }) => {
  React.useEffect(() => {
    window.scrollTo(0, 0);
    setContactForm(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const env: EnvVars = process.env as any;
  const emailServiceStr: string = env.REACT_APP_EMAILSERVICE;
  const emailTemplateStr: string = env.REACT_APP_EMAILTEMPLATE;
  const emailKeyStr: string = env.REACT_APP_EMAILKEY;

  const formatNumber = (input: string) => {
    let cleaned = ("" + input).replace(/\D/g, "");

    let returnVal = `${cleaned.length > 0 ? "(" : ""}${cleaned.substring(0, 3)}${cleaned.length > 3 ? ") " : ""}${cleaned.substring(3, 6)}${cleaned.length > 6 ? " - " : ""}${cleaned.substring(6, 10)}`
    return returnVal;
  }

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.name === "phoneNumber") {
      e.target.value = formatNumber(e.target.value);
    }

    setFormData((prevData: FormI) => {
      return { ...prevData, [e.target.name]: e.target.value };
    });
  };

  const handleToggle = (e: React.ChangeEvent<HTMLInputElement>) => {
    let tempVal: boolean = formData?.warranty ? false : true;
    setFormData((prevData: FormI) => {
      return { ...prevData, [e.target.name]: tempVal };
    });
  };

  // Modal variables
  const [openModal, setOpenModal] = React.useState(false);
  const handleOpenModal = () => setOpenModal(true);
  const handleCloseModal = () => {
    setSnackIncomplete(false);
    setSnackError(true);
    setOpenSnack(true);
    setOpenModal(false);
  };

  // Snackbar notification variables
  const [openSnack, setOpenSnack] = React.useState(false);
  const [snackError, setSnackError] = React.useState(false);
  const [snackIncomplete, setSnackIncomplete] = React.useState(false);
  const handleClose = (event?: React.SyntheticEvent | Event, reason?: string) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenSnack(false);
  }

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    if (!formData?.fullName) {
      setSnackIncomplete(true);
      setOpenSnack(true);
      window.scrollTo(0, 0);
      return;
    }

    if (formData?.phoneNumber.length !== 16) {
      setSnackIncomplete(true);
      setOpenSnack(true);
      window.scrollTo(0, 0);
      return;
    }

    if (!formData?.zipCode) {
      setSnackIncomplete(true);
      setOpenSnack(true);
      window.scrollTo(0, 60);
      return;
    }

    if (!formData?.problem) {
      setSnackIncomplete(true);
      setOpenSnack(true);
      return;
    }

    handleOpenModal()
  };

  const handleAccept = () => {
    let params = {
      fullName: formData?.fullName,
      phoneNumber: formData?.phoneNumber,
      zipCode: formData?.zipCode,
      problem: formData?.problem,
      email: formData?.email,
      address: formData?.address,
      appliance: formData?.appliance,
      applianceDetails: formData?.applianceDetails,
      warranty: formData?.warranty ? "Yes" : "No",
    };

    emailjs
      .send(emailServiceStr, emailTemplateStr, params, emailKeyStr)
      .then(
        (result) => {
          handleCloseModal();
          setSnackIncomplete(false);
          setSnackError(false);
          setOpenSnack(true);
          setFormData({
            fullName: "",
            phoneNumber: "",
            zipCode: "",
            problem: "",
            email: "",
            address: "",
            appliance: "",
            applianceDetails: "",
            warranty: false,
          });
        },
        (error) => {
          handleCloseModal();
          setSnackError(true);
          setOpenSnack(true);
        }
      );
  }

  return (
    <div className="whole-form">
      <h2 className="contact-form-title">Contact Form</h2>
      <div className="center-div">
        <form
          className="contact-form"
          onSubmit={(e) => handleSubmit(e)}
        >
          <section>
            <h4 className="section-header">Contact Information</h4>
            <div className="contact-information-grid">
              <fieldset>
                <TextField
                  InputProps={{ style: { fontFamily: "Nunito" } }}
                  InputLabelProps={{ style: { fontFamily: "Nunito" } }}
                  label="Full Name:"
                  variant="outlined"
                  name="fullName"
                  onChange={handleChange}
                  value={formData?.fullName}
                  error={formData?.fullName ? false : true}
                />
              </fieldset>
              <fieldset>
                <TextField
                  InputProps={{ style: { fontFamily: "Nunito" } }}
                  inputProps={{ inputMode: "numeric" }}
                  InputLabelProps={{ style: { fontFamily: "Nunito" } }}
                  label="Phone Number:"
                  placeholder="This field only accepts numbers."
                  variant="outlined"
                  name="phoneNumber"
                  onChange={handleChange}
                  value={formData?.phoneNumber}
                  error={formData?.phoneNumber.length < 16 ? true : false}
                />
              </fieldset>
              <fieldset>
                <TextField
                  InputProps={{ style: { fontFamily: "Nunito" } }}
                  InputLabelProps={{ style: { fontFamily: "Nunito" } }}
                  label="Email:"
                  variant="outlined"
                  name="email"
                  onChange={handleChange}
                  value={formData?.email}
                />
              </fieldset>
            </div>
          </section>

          <section>
            <h4 className="section-header">Address</h4>
            <div className="address-information-grid">
              <fieldset>
                <ZipCodeSelect
                  formData={formData}
                  setFormData={setFormData}
                />
              </fieldset>
              <fieldset>
                <TextField
                  InputProps={{ style: { fontFamily: "Nunito" } }}
                  InputLabelProps={{ style: { fontFamily: "Nunito" } }}
                  label="Street Address:"
                  variant="outlined"
                  name="address"
                  onChange={handleChange}
                  value={formData?.address}
                />
              </fieldset>
            </div>
          </section>

          <section>
            <h4 className="section-header">Appliance Information</h4>
            <label className="appliance-category-header">
              Appliance Category:
            </label>
            <div className="appliance-information-grid">
              <div className="radio-grid">
                <div className="radio-grid-item">
                  <input
                    type="radio"
                    value="refrigeration"
                    name="appliance"
                    checked={
                      formData?.appliance === "refrigeration" ? true : false
                    }
                    onChange={handleChange}
                  />
                  <label>Refrigeration</label>
                </div>
                <div className="radio-grid-item">
                  <input
                    type="radio"
                    value="laundry"
                    name="appliance"
                    checked={formData?.appliance === "laundry" ? true : false}
                    onChange={handleChange}
                  />
                  <label>Laundry</label>
                </div>
                <div className="radio-grid-item">
                  <input
                    type="radio"
                    value="cooking"
                    name="appliance"
                    checked={formData?.appliance === "cooking" ? true : false}
                    onChange={handleChange}
                  />
                  <label>Cooking</label>
                </div>
                <div className="radio-grid-item">
                  <input
                    type="radio"
                    value="dishwasher"
                    name="appliance"
                    checked={
                      formData?.appliance === "dishwasher" ? true : false
                    }
                    onChange={handleChange}
                  />
                  <label>Dishwasher</label>
                </div>
                <label className="appliance-warranty-header">
                  Appliance Warranty:
                </label>
                <div className="appliance-warranty-flex">
                  <input
                    type="checkbox"
                    name="warranty"
                    onChange={handleToggle}
                    checked={formData?.warranty}
                  />
                  <label>Covered by Warranty</label>
                </div>
              </div>
              <fieldset className="appliance-details">
                <TextField
                  InputProps={{ style: { fontFamily: "Nunito" } }}
                  InputLabelProps={{ style: { fontFamily: "Nunito" } }}
                  label="Appliance Details:"
                  placeholder="Please provide any known appliance details here."
                  variant="outlined"
                  name="applianceDetails"
                  onChange={handleChange}
                  value={formData?.applianceDetails}
                  multiline
                  minRows={3}
                  maxRows={3}
                />
              </fieldset>
            </div>
          </section>

          <section>
            <div className="problem-input">
              <fieldset>
                <TextField
                  InputProps={{ style: { fontFamily: "Nunito" } }}
                  InputLabelProps={{ style: { fontFamily: "Nunito" } }}
                  label="Problem:"
                  id="problem"
                  placeholder="Please use this area to describe the problem you are having in as much detail as possible."
                  variant="outlined"
                  name="problem"
                  onChange={handleChange}
                  value={formData?.problem}
                  error={formData?.problem ? false : true}
                  multiline
                  minRows={3}
                  maxRows={3}
                />
              </fieldset>
            </div>
          </section>
          <Button
            type="submit"
            style={{ backgroundColor: "#ff9100", fontFamily: "PT Sans", fontStyle: "italic", fontSize: "25px" }}
            className="submit-button"
            variant="contained"
            size="small"
          >
            Submit Form
          </Button>
        </form>
        <SnackbarNotification openSnack={openSnack} handleClose={handleClose} snackError={snackError} snackIncomplete={snackIncomplete} />
        <FormSubmitModal openModal={openModal} handleCloseModal={handleCloseModal} handleAccept={handleAccept} />
      </div>
    </div>
  );
};

export default ContactForm;