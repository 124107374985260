import * as React from "react";
import { useNavigate } from "react-router-dom";

// external components
import ArrowLeftTwoTone from "@mui/icons-material/ArrowLeftTwoTone";
import ArrowRightTwoTone from "@mui/icons-material/ArrowRightTwoTone";

// internal components
import FabPhone from "../FabPhone/FabPhone";
import logo from "../../img/ApplianceAuthority-WhiteLogo.png";

import "./header.css";

interface Props {
  contactForm: boolean;
  setInfoPage: (value: boolean) => void;
}

const Header: React.FC<Props> = ({ contactForm, setInfoPage }) => {
  const navigate = useNavigate();
  const handleClick = () => {
    let route = contactForm ? "/" : "/contactform";
    navigate(route, { replace: true });
    setInfoPage(false);
  };

  return (
    <>
      <header>
        <img
          className="header-title"
          src={logo}
          alt="Appliance Authority Logo"
        />
          {!contactForm ? (
            <div className="navbar-form">
              <p onClick={handleClick}>Contact Form</p>
              <ArrowRightTwoTone onClick={handleClick} />
            </div>
          ) : (
            <div className="navbar-return">
              <ArrowLeftTwoTone onClick={handleClick} />
              <p onClick={handleClick}>Return Home</p>
            </div>
          )}
      </header>
      <FabPhone />
    </>
  );
};

export default Header;