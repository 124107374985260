import * as React from 'react';
import { useNavigate } from 'react-router-dom';

import ContentCopyIcon from '@mui/icons-material/ContentCopy';

import './footer.css';

interface Props {
  setContactForm: (value: boolean) => void;
  setInfoPage: (value: boolean) => void;
}


const Footer: React.FC<Props> = ({ setContactForm, setInfoPage }) => {

    const navigate = useNavigate();

    const handleClick = () => {
        navigate("/information", { replace: true });
        setContactForm(true);
        setInfoPage(true);
      };

    const handleCopy = () => {
        navigator.clipboard.writeText("applianceauthority469@gmail.com");
    }

    return (
        <footer>
            <div className='info-footer'>
                <div>
                    <ul>
                        <li style={{ fontWeight: "900"}}>Contact Us!</li>
                        <li>Phone: (469) 317 - 3997</li>
                        <li style={{ display: "flex", alignItems: "center", cursor: "pointer" }} title="Click to Copy!" onClick={handleCopy}>
                            Email: applianceauthority469@gmail.com &nbsp; <ContentCopyIcon sx={{ fontSize: '16px' }}/>
                            </li>
                        <hr />
                        <li style={{ fontWeight: "900"}}>Hours:</li>
                        <li>Monday - Friday: 9am to 5pm</li>
                        <hr className='footer-break'/>
                    </ul>
                </div>
                <div>
                    <ul className='information-links'>
                        <li style={{ fontWeight: "900"}}>Helpful Links:</li>
                        <li onClick={handleClick}>About</li>
                        <li onClick={handleClick}>Privacy Policy</li>
                        <li onClick={handleClick}>Credits</li>
                    </ul>
                </div>
            </div>
        </footer>
    )
}

export default Footer;